<template>
  <v-card
    :dark="dark"
    :light="light"
    :color="color"
  >
    <slot v-if="!!$slots.title" name="title" />
    <div
      v-if="loading"
      class="d-flex flex-row flex-grow justify-center align-center pa-2"
    >
      <v-progress-circular indeterminate />
    </div>
    <v-carousel
      v-else
      height="auto"
      :hide-delimiter-background="hideDelimiterBackgorund"
      :show-arrows="showArrows"
      :delimiter-icon="delimiterIcon"
      :dark="carouselDark"
      :light="carouselLight"
      :color="carouselColor"
      class="carousel-delimeter-outside"
    >
      <template v-for="(item, index) in products">
        <v-carousel-item
          v-if="(index + 1) % columns === 1 || columns === 1"
          :key="index"
        >
          <v-row class="flex-nowrap fill-height" no-gutters justify="center" align="center">
            <template v-for="(n,i) in columns">
              <template v-if="(+index + i) < products.length">
                <v-col :key="i" :cols="columnSize">
                  <div
                    v-if="(+index + i) < products.length"
                    class="d-flex flex-row align-center justify-center pb-1 ma-1"
                  >
                    <product-carousel-item v-if="!showFullCard" :value="products[+index + i]" />
                    <product-card v-else dense :value="products[+index + i]" />
                  </div>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
  </v-card>
</template>

<script>
  import ProductList from '@peynman/press-vue-core/mixins/ProductList'
  import ProductCarouselItem from './ProductCarouselItem.vue'
  import ProductCard from '../ProductCard/ProductCard.vue'

  export default {
    name: 'ProductsCarousel',
    components: {
      ProductCarouselItem,
      ProductCard,
    },
    mixins: [
      ProductList,
    ],
    props: {
      label: String,
      color: String,
      dark: Boolean,
      light: Boolean,
      carouselColor: String,
      carouselDark: Boolean,
      carouselLight: Boolean,
      showFullCard: Boolean,
      showArrows: {
        type: Boolean,
        default: false,
      },
      delimiterIcon: {
        type: String,
        default: 'mdi-circle-medium',
      },
      hideDelimiterBackgorund: {
        type: Boolean,
        default: true,
      },
      categories: Array,
      elevation: [Number, String],
      limit: {
        type: [Number, String],
        default: 10,
      },
      xl: {
        type: [Number, String],
        default: 1,
      },
      lg: {
        type: [Number, String],
        default: 2,
      },
      m: {
        type: [Number, String],
        default: 3,
      },
      sm: {
        type: [Number, String],
        default: 4,
      },
      xs: {
        type: [Number, String],
        default: 6,
      },
    },
    data: vm => ({
    }),
    computed: {
      columns () {
        if (this.$vuetify.breakpoint.xl) {
          return Math.ceil(12 / parseInt(this.xl))
        }

        if (this.$vuetify.breakpoint.lg) {
          return Math.ceil(12 / parseInt(this.lg))
        }

        if (this.$vuetify.breakpoint.md) {
          return Math.ceil(12 / parseInt(this.md))
        }

        if (this.$vuetify.breakpoint.sm) {
          return Math.ceil(12 / parseInt(this.sm))
        }

        return Math.ceil(12 / parseInt(this.xs))
      },
      columnSize () {
        if (this.$vuetify.breakpoint.xl) {
          return parseInt(this.xl)
        }

        if (this.$vuetify.breakpoint.lg) {
          return parseInt(this.lg)
        }

        if (this.$vuetify.breakpoint.md) {
          return parseInt(this.md)
        }

        if (this.$vuetify.breakpoint.sm) {
          return parseInt(this.sm)
        }

        return this.xs
      },
    },
    watch: {
      categories () {
        this.filters = {
          categories: this.categories?.reduce((obj, c) => {
                        obj[c] = true
                        return obj
                    }, {}),
        }
        this.updateProductsList()
      },
    },
    mounted () {
      this.filters = {
        categories: this.categories?.reduce((obj, c) => {
                    obj[c] = true
                    return obj
                }, {}),
      }
      this.updateProductsList()
    },
  }
</script>
