<template>
  <v-input
    :value="internalValue"
    dense
    hide-details
    :rules="[ getRequiredRule(), getMinRule(1) ]"
    class="d-flex flex-row align-center"
    :loading="isLoading"
  >
    <v-btn icon small :disabled="isLoading || internalValue <= min" @click="decreaseQuantity"><v-icon small>mdi-minus</v-icon></v-btn>
    <v-chip label small>{{ $n(internalValue, 'decimal') }}</v-chip>
    <v-btn icon small :disabled="isLoading || internalValue >= max" @click="increaseQuantity"><v-icon small>mdi-plus</v-icon></v-btn>
  </v-input>
</template>

<script>
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'

  export default {
    name: 'ProductQuantityPicker',
    mixins: [
      FormValidations(),
    ],
    props: {
      value: [Number, String],
      min: {
        type: Number,
        default: 1,
      },
      max: {
        type: Number,
        default: 1000,
      },
    },
    data: vm => ({
      internalValue: vm.value ?? 1,
    }),
    computed: {
      isLoading () {
        return this.$store.getters['cart/isLoading']
      },
    },
    watch: {
      value () {
        this.internalValue = this.value ?? 1
      },
    },
    methods: {
      increaseQuantity () {
        this.internalValue = parseInt(this.internalValue) + 1
        this.$emit('input', this.internalValue)
      },
      decreaseQuantity () {
        if (parseInt(this.internalValue) >= 2) {
          this.internalValue = parseInt(this.internalValue) - 1
          this.$emit('input', this.internalValue)
        }
      },
    },
  }
</script>
