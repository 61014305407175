<template>
  <v-card :to="`/shop/product/${value.id}`">
    <v-img
      :src="imgSrc"
      contain
    >
      <template v-slot:placeholder>
        <v-row align="center" justify="center" class="fill-height">
          <v-progress-circular indeterminate />
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
  import Product from '@peynman/press-vue-core/mixins/Product'

  export default {
    name: 'ProductCarouselItem',
    mixins: [
      Product,
    ],
    props: {
      value: Object,
    },
    computed: {
      img () {
        return this.heroImages?.[0] ? this.heroImages?.[0] : this.carouselImages?.[0] ?? ''
      },
      imgSrc () {
        return this.getImageThumbnail(this.img.image, 1)
      },
    },
  }
</script>
